<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addForwarderCost') : $t('message.updateForwarderCost') + ": " + productName }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="importDutyForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.productGroup') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="allProductGroups"
                                    :rules="[...validationRules.required]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="ProductGroup.title"
                                    item-value="ProductGroup.id"
                                    solo
                                    v-model="ImportDuty__productgroup_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.warehouse') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="filteredOffices"
                                    :rules="[...validationRules.required]"
                                    autocomplete="off"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Office.title"
                                    item-value="Office.id"
                                    solo
                                    v-model="ImportDuty__office_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.origin') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="allCountries"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Country.name"
                                    item-value="Country.id"
                                    solo
                                    v-model="ImportDuty__productorigin_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.duty') + ' %' }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.amount]"
                                    :value="ImportDuty__duty"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="ImportDuty__duty = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addImportDuty()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveImportDuty()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {api} from '../../api';
import {mapActions, mapGetters} from "vuex";
export default {
    name: "AddImportDuty",
    props: ['importDutyId','dialog','updateMode','productName'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            rules: {
                duty: v => !!v || this.$t('message.required'),
                origin: v => !!v || this.$t('message.required'),
                product: v => !!v || this.$t('message.required'),
                warehouse: v => !!v || this.$t('message.required'),
            },
            validForm: true,
            ImportDuty__id: null,
            ImportDuty__duty: null,
            ImportDuty__productgroup_id: null,
            ImportDuty__office_id: 1,
            ImportDuty__productorigin_id: 1
        }
    },
    computed: {
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters([
            'offices',
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        filteredOffices() {
            return this.offices.filter(o => {
                return [1,21].includes(o.Office.id)
            })
        }
    },
    methods: {
        ...mapActions('country', {
            getAllCountries: 'getAllCountries'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        addImportDuty() {
            if(this.$refs.importDutyForm.validate()) {
                this.loading.add = true
                const warehouseVar = {
                    productgroup_id: this.ImportDuty__productgroup_id,
                    office_id: this.ImportDuty__office_id,
                    productorigin_id: this.ImportDuty__productorigin_id,
                    duty: this.ImportDuty__duty
                }
                api
                    .post('/warehouse-vars/import-duty', {
                        warehouseVar: warehouseVar
                    })
                    .then((response) => {
                        const status = response.data.status
                        if (status == 'success') {
                            this.$toast.success(this.$t('message.successes.importDutyAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.importDutyNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.importDutyNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.countryNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            return true
        },
        getImportDutyToUpdateById() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/import-duty/' + this.importDutyId)
                    .then((response) => {
                        const status = response.data.status
                        if(status == 'success'){
                            const data = response.data.data[0]
                            this.ImportDuty__id = data.ImportDuty.id
                            this.ImportDuty__duty = data.ImportDuty.duty
                            this.ImportDuty__productgroup_id = data.ImportDuty.productgroup_id
                            this.ImportDuty__office_id = data.ImportDuty.office_id
                            this.ImportDuty__productorigin_id = data.ImportDuty.productorigin_id
                            resolve('done')
                        } else {
                            reject('error')
                        }
                    })
                    .catch((err) => reject(err))
            })
        },
        resetForm() {
            this.ImportDuty__productorigin_id = 1
            this.ImportDuty__office_id = 1
            this.ImportDuty__productgroup_id = null
            this.ImportDuty__duty = null
            this.ImportDuty__id = null
        },
        saveImportDuty() {
            if (this.$refs.importDutyForm.validate()) {
                this.loading.save = true
                const warehouseVar = {
                    productgroup_id: this.ImportDuty__productgroup_id,
                    office_id: this.ImportDuty__office_id,
                    productorigin_id: this.ImportDuty__productorigin_id,
                    duty: this.ImportDuty__duty
                }
                api
                    .put('/warehouse-vars/import-duty/' + this.importDutyId, {
                        warehouseVar: warehouseVar
                    })
                    .then((response) => {
                        const status = response.data.status
                        if (status == 'success') {
                            this.$toast.success(this.$t('message.successes.importDutyUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.importDutyNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.importDutyNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.importDutyNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
            return true
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getImportDutyToUpdateById()
                        .then(() => {
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                } else {
                    this.resetForm()
                }
            } else {
                this.resetForm()
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allCountries.length == 0) this.getAllCountries()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
    }
}
</script>

<style scoped>

</style>